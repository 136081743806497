export enum Route {
  // hygen generated enums (don't remove this comment)
  MyTasks = 'myTasks',
  Reporting = 'reporting',
  Materiality = 'materiality',
  MaterialityImpact = 'materialityImpact',
  MaterialityFinancial = 'materialityFinancial',
  MaterialityAssessmentCriteria = 'materialityAssessmentCriteria',
  Audit = 'audit',
  Insights = 'insights',
  SettingsDefault = 'settings',
  SettingsOrganization = 'settingsOrganization',
  SettingsLocations = 'settingsLocations',
  SettingsUsers = 'settingsUsers',
  SettingsMyAccount = 'settingsMyAccount',
  Footprint = 'footprint',
  FootprintActivities = 'footprintActivities',
  FootprintEntry = 'footprintEntry',
  FootprintResult = 'footprintResult',
  Actions = 'actions',
  ActionsRoadmap = 'actionsRoadmap',
  ActionsRoadmapQuarters = 'actionsRoadmapQuarters',
  Action = 'action',
  /**
   * @deprecated it'll redirect to the ActionsRoadmap
   */
  Assessment = 'assessment',
  ActionsMyActions = 'actionsMyActions',
  HoldingStructure = 'holdingStructure',
  Onboarding = 'onboarding',
  OnboardingWelcome = 'onboardingWelcome',
  OnboardingActionsManual = 'onboardingActionsManual',
  OnboardingActionsQuick = 'onboardingActionsQuick',
  OnboardingFinishing = 'onboardingFinishing',
  NotOnboarded = 'notOnboarded',
  Dashboard = 'dashboard',
  AssessmentEmissions = 'assessmentEmissions',
  AssessmentReductionPath = 'assessmentReductionPath',
  /** Navigate to the default assessment's actions category */
  AssessmentActions = 'assessmentActions',
  /**
   * Navigate to a specific assessment's actions category
   * @param categoryId of `ActionCategory` enum type
   */
  AssessmentActionsCategory = 'assessmentActionsCategory',
  AssessmentActionsScope = 'assessmentActionsScope',
  AssessmentActionsScopeDefault = 'assessmentActionsScopeDefault',
  AssessmentActionsCategoryDefault = 'assessmentActionsCategoryDefault',
  AssessmentCalculations = 'assessmentCalculations',
  FootprintEntryDefault = 'footprintEntryMain',
  FootprintEntryYear = 'footprintEntryYear',
  FootprintEntryYearDefault = 'footprintEntryYearMain',
  FootprintEntryYearPoint = 'footprintEntryYearPoint',
  FootprintDataRooms = 'footprintDataRooms',
  FootprintDataRoom = 'footprintDataRoom',
}

/**
 * Keys for global route error handler
 * @description to trigger error handler pass `RouteError` to `error` param of the `route`. Error handler is located in `AppRouterGuard.vue`
 * @example router.push({ query: { errorType: RouteError.AccessDenied }})
 */
export enum RouteError {
  /**
   * 403
   */
  AccessDenied = 'AccessDenied',
}

/**
 * Key for `useHistoryState`
 */
export enum RouteHistoryState {
  Actions = 'Actions',
  Dashboard = 'Dashboard',
}
